import _ from 'lodash'
import crud from './crud'

let breadcrumbs = {}

// Crud items
_.map(crud, (item) => {
    _.set(breadcrumbs, `${item.module}.index`, [
        {
            text: _.upperFirst(_.get(item, 'name.0', 'элементы')),
            disabled: true,
            href: `/${item.module}`,
        }
    ]);
    _.set(breadcrumbs, `${item.module}.create`, [
        {
            text: _.upperFirst(_.get(item, 'name.0', 'элементы')),
            disabled: false,
            href: `/${item.module}`,
        },
        {
            text: `Создание ${_.get(item, 'name.1', 'элемента')}`,
            disabled: true,
            href: `/${item.module}/create`,
        }
    ])
    _.set(breadcrumbs, `${item.module}.update`, [
        {
            text: _.upperFirst(_.get(item, 'name.0', 'элементы')),
            disabled: false,
            href: `/${item.module}`,
        },
        {
            text: `Редактирование ${_.get(item, 'name.1', 'элемента')}`,
            disabled: true,
            href: `/${item.module}/update/:id`,
        }
    ])
})

export default breadcrumbs