export default {
    defaultSubtaskObject: {
        id: 0,
        html: '',
        htmlPrintable: '',
        scoringType: 'scoresSum',
        inputs: [],
        groups: [],
        attachedFile: null
    },
    color: {
        primary: 'cyan darken-2'
    },
    editorBlockTypes: {
        'formula-input': 'Поле ввода формулы',
        'drag-n-drop': 'Таскалка',
        'text-input': 'Однострочное поле ввода',
        'text-input-multiline': 'Многострочное поле ввода',
        'family-tree': 'Семейное дерево',
        'click-and-sign': 'Нажми и подпиши',
        'dropdown': 'Выпадающее меню',
        'text-input-fraction': 'Ввод дробей',
        'select-cells': 'Выделение клеток',
        'draw-on-grid': 'Рисование по сетке',
        'draw-polyline': 'Рисование отрезков',
        'conditional-display': 'Условное отображение',
        'parsing-sentence': 'Разбор предложения',
        'parsing-word': 'Разбор слова',
        'accent-in-word': 'Ударение в словах',
        'audio-record': 'Запись голоса',
        'audio-dictation': 'Диктант',
        'calendar': 'Календарь',
        'divide-figure': 'Деление фигуры',
        'mirrored-figure': 'Отраженная фигура',
        'free-drawing': 'Свободное рисование',
        'truth-table': 'Таблица истинности',
        'audio-stepper': 'Вопросы по диктанту',
        'record-stepper': 'Аудирование',
        'attach-file-btn': 'Кнопка "Прикрепить файл"',
        'insert-letters': 'Вставка символов в текст'
    }
}