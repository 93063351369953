import ApiHelper from '@/helpers/ApiHelper'
import { JOB_SERVER } from '@/servers'

export default {
    namespaced: true,
    state: {
        currentYear: null
    },
    mutations: {
        SET_VALUE (state, payload) {
            if (!Array.isArray(payload)) { throw new Error('"payload" argument must be an Array') }
            if (payload.length !== 2) { throw new Error('"payload" argument must have exactly 2 elements') }
            state[payload[0]] = payload[1]
        }
    },
    actions: {
        async fetchCurrentYear ({ commit }) {
            const { success, data, error } = await ApiHelper.get(`${ JOB_SERVER }/api/site/current-year`);

            if (!success) {

                console.error(error)
                return false
            }
            commit('SET_VALUE', ['currentYear', data.year])
        }
    }
}