// Code server
const CODE_SERVER = process.env.VUE_APP_CODE_SERVER_HOST;
const CODE_SERVER_API_KEY = process.env.VUE_APP_CODE_SERVER_API_KEY;
// Job server
const JOB_SERVER = process.env.VUE_APP_JOB_SERVER_HOST;
const JOB_SERVER_API_KEY = process.env.VUE_APP_JOB_SERVER_API_KEY;

const APP_SERVER_HOST = process.env.VUE_APP_APP_SERVER_HOST;

export { 
	CODE_SERVER, CODE_SERVER_API_KEY,
	JOB_SERVER, JOB_SERVER_API_KEY,
    APP_SERVER_HOST
}