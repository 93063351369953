// Аналогичный файл находится в frontend-user и frontend-app. Если здесь будут изменения, то надо обновить и там. По хорошему это надо вынести на какой-то бекенд
export default [
    {
        value: 'Английский язык',
        text: 'Английский язык',
        grades: [7]
    },
    {
        value: 'Биология',
        text: 'Биология',
        grades: [5, 6, 7, 8]
    },
    {
        value: 'География',
        text: 'География',
        grades: [6, 7, 8]
    },
    {
        value: 'История',
        text: 'История',
        grades: [5, 6, 7, 8]
    },
    {
        value: 'Информатика',
        text: 'Информатика',
        grades: [7, 8]
    },
    {
        value: 'Литературное чтение',
        text: 'Литературное чтение',
        grades: [4]
    },
    {
        value: 'Литература',
        text: 'Литература',
        grades: [5, 6, 7, 8]
    },
    {
        value: 'Математика',
        text: 'Математика',
        grades: [4, 5, 6, 7, 8]
    },
    {
        value: 'Обществознание',
        text: 'Обществознание',
        grades: [6, 7, 8]
    },
    {
        value: 'Окружающий мир',
        text: 'Окружающий мир',
        grades: [4]
    },
    {
        value: 'Русский язык',
        text: 'Русский язык',
        grades: [4, 5, 6, 7, 8]
    },
    {
        value: 'Физика',
        text: 'Физика',
        grades: [7, 8]
    },
    {
        value: 'Химия',
        text: 'Химия',
        grades: [8]
    }
]